<template>
		<div>	
		<div class="sms-content" style="text-align: center; padding-top: 41px; padding-bottom: 41px;">
		<div class="sms-alert-icon">
			<img v-if="paymentStatus == 'expired'" src="@/assets/icon/시간만료.png" />
			<img v-else src="@/assets/icon/결제실패_원형.png" />
		</div>
		<div v-if="paymentStatus == 'expired'" class="sms-text">시간이 초과된 결제페이지입니다.<br>결제가 필요한 경우 판매자에게 문의하세요.</div>
		<div v-else-if="paymentStatus == 'cancel'" class="sms-text">판매가 정지된 결제페이지입니다.<br>판매자에게 문의하세요.</div>
		<div v-else-if="paymentStatus == 'success'" class="sms-text">이미 결제가 완료되었습니다.</div>
		<div v-else-if="paymentStatus == 'fail'" class="sms-text">결제에 실패했습니다.<br>카드 정보를 확인하거나<br>다른 카드를 선택하십시오.</div>
		<div v-else class="sms-text">잘못된 결제 정보입니다</div>
		</div>
		</div>
</template>

<script>
	export default {
	name: 'PaymentSmsFail'
	, props: ['callBack', 'statusCode']
	, components: {}
	, data: function () {
		return {
		program: {
			name: 'PaymentSmsFail'
			, not_title: true
			, not_navigation: true
		}
		, item: {}
		, paymentStatus: ''
		}
	}
	, created: function () {
		this.paymentStatus = this.statusCode
		if(this.paymentStatus == 'success'){
			this.$router.push({ name: 'payment'})
		}
	}
	, config: {
		immediate: true
		, handler: function (call) {
		this.item = call

		}
	}
	, methods: {
		rePayment: function () {
		this.$emit('rePayment')
		}
	}
	}
</script>
<style>
	.sms-body .sms-content .sms-text {font-family: 'Roboto'; font-size: 15px; font-weight: bold; line-height: 26px}
	.sms-alert-icon {text-align: center; margin: 41px;}
</style>









