<template>

		
	<v-dialog
		fullscreen
		v-model="dialog"
	>
		<div
			class="receipt bg-success2 pa-5"
			style="height: 100%;"
		>
			<div>
				<v-card
					class="bg-white pt-10"
					:style="{ backgroundImage: 'url(' + require('@/assets/img/receipt-top-none.jpg') + '), url(' + require('@/assets/img/receipt-bottom-none.jpg') + ')'}"
					elevation="0"
					tile
				>
					<v-card-title
						class="text-center font-weight-bold d-block pt-10"
					>
					
						<v-icon
							color="success2"
							large
						>mdi-check-circle</v-icon>
						
						
						<div class="mt-5">결제 성공</div>
						
					</v-card-title>
					
					<v-card-text
						class="mt-10 text-caption"
					>
						<v-row>
							<v-col>
								구매상품
							</v-col>
							<v-col
								class="pt-0 text-subtitle-1">
								{{ item.productNm }}
							</v-col>
						</v-row>
						<v-row>
							<v-col
								class="pt-0"
							>
								결제 금액
							</v-col>
							<v-col
								class="pt-0 text-subtitle-1"
							>
								<span class="text-color font-weight-bold">{{ item.totAmt | makeComma }} 원</span>
							</v-col>
						</v-row>
						
						<v-divider class="mt-3 mb-5"></v-divider>
						
						<v-row>
							<v-col>
								결제일시
							</v-col>
							<v-col
								cols="8"
							>
								{{ item.tranDt | transDate}} {{ item.tranTm | transTime}}
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								승인번호
							</v-col>
							<v-col>
								{{ item.tranSeq }}
							</v-col>
						</v-row>
						
						<v-row>
							<v-col>
								할부기간
							</v-col>
							<v-col>
								{{ item.installment ? item.installment : '일시불' }}
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								거래번호
							</v-col>
							<v-col>
								{{ item.orderSeq }}
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
				
				<div
					:style="{ backgroundImage: 'url(' + require('@/assets/img/receipt-bottom-none.jpg') + ')'} "
					elevation="0"
					style="height: 40px;"
				></div>
				<v-row>
					<v-col>
						<v-btn
							block
							outlined
							color="white"
							@click="sendReceipt"
						>
							영수증 전송
						</v-btn>
					</v-col>
					<v-col>
						<v-btn
							block
							color="white"
							@click="close"
						>
							닫기
						</v-btn></v-col>
				</v-row>
			</div>
		</div>
			
	</v-dialog>
</template>

<script>
	export default{
		name: 'PaymentReceipt'
		,created: function(){
			this.$emit('setProgram', this.program)
		}
		,props: ['user', 'item', 'callBack', 'dialog']
		,data: function(){
			return {
				program: {
					title: '영수증'
					,not_title: true
					,not_navigation: true
				}
				,installment: [
					{
						text: '일시불'
						,value: '00'
					}
					,{
						text: '2개월'
						,value: '02'
					}
					,{
						text: '3개월'
						,value: '03'
					}
					,{
						text: '4개월'
						,value: '04'
					}
					,{
						text: '5개월'
						,value: '05'
					}
					,{
						text: '6개월'
						,value: '06'
					}
				]
			}
		}
		,methods: {
			close: function(){
				this.$emit('closeReceipt')
			}
			,sendReceipt: function(){
				this.$emit('sendReceipt')
			}
		}
		,watch: {
			callBack: {
				deep: true
				,handler: function(call){
					console.log(call)
				}
			}
		}
	}
</script>

<style>
	.receipt {}
	.receipt .col {padding: 5px 12px;}
	.receipt .col:nth-child(odd) {font-weight: bold;}
	.receipt .col:nth-child(even) {text-align: right;}
</style>