<template>

	<div>
		
		<div
			class="sms-content"
		>
			<div class="mb-5 mt-1 font-weight-bold">
				신용카드 정보
			</div>
				<v-text-field
					v-model="item.totAmt"
					label="결제금액"
					
					required
					hide-details
					color="success2"
					readonly
					outlined
					class="" style="background: #eee;"
				></v-text-field>
				
				<v-text-field
					v-model="item.goodsName"
					label="상품명"
					
					required
					hide-details
					color="success2"
					readonly
					outlined
					class="mt-5" style="background: #eee;"
				></v-text-field>
				
				<div class="mt-3">카드번호</div>
				<v-row
					class="pa-0 pt-1 pb-1 ma-0"
				>
					<v-col class="pa-0 ma-0">
						<v-text-field
							v-model="cardNumber[0]"
							
							required
							hide-details
							color="success2"
							
							class="mr-1 pa-0"
							type="number"
							:rules="[rules.card1]"
						></v-text-field>
					</v-col>
					<v-col class="pa-0">
						<v-text-field
							v-model="cardNumber[1]"
							
							required
							hide-details
							color="success2"
							type="password"
							class="mr-1 pa-0"
							:rules="[rules.card2]"
						></v-text-field>
					</v-col>
					<v-col class="pa-0">
						<v-text-field
							v-model="cardNumber[2]"
							
							required
							hide-details
							type="password"
							color="success2"
							
							:rules="[rules.card3]"
							class="mr-1 pa-0"
						></v-text-field>
					</v-col>
					<v-col class="pa-0">
						<v-text-field
							v-model="cardNumber[3]"
							
							required
							hide-details
							color="success2"
							
							type="number"
							:rules="[rules.card4]"
							class="mr-1 pa-0"
						></v-text-field>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<v-text-field
							v-model="item.expireMm"
							label="MM"
							
							required
							hide-details
							color="success2"
							class="mt-2"
							type="number"
							:rules="[rules.expireMm]"
						></v-text-field>
					</v-col>
					<v-col>
						<v-text-field
							v-model="item.expireYy"
							label="YY"
							
							required
							hide-details
							color="success2"
							type="number"
							:rules="[rules.expireYy]"
							class="mt-2"
						></v-text-field>
					</v-col>
				</v-row>
				
				<v-text-field
					v-model="item.cardPassword"
					label="비밀번호"
					
					placeholder="비밀번호 앞 2자리"
					type="password"
					required
					hide-details
					color="success2"
					class="mt-2"
					:rules="[rules.cardPassword]"
				></v-text-field>
				
				<v-text-field
					v-model="item.certification"
					label="본인 확인"

					placeholder="생년월일 6자리 / 사업자번호 10자리"
					required
					hide-details
					color="success2"
					class="mt-2"
					:rules="[rules.certification]"
				></v-text-field>
				
				<v-select 
					v-model="item.installment"
					:items="codes.installment"
					item-text="codeName"
					item-value="code"
					label="할부"
					
					color="success2"
					hide-detail
					class="mt-5"
					:disabled="disabled.installment"
				></v-select>
				
			</div>
		
		<v-row>
			<v-col>
				<v-btn 
					class="mt-10 "
					block
					@click="prev"
				>
					취소
				</v-btn>
			</v-col>
			<v-col>
<!--				<v-btn class="mt-10 success2" block @click="save" :disabled="disabled.save">-->
        <v-btn
            class="mt-10 success2"
            block
            @click="save"
            :disabled="disabled.save"
        >
					결제
				</v-btn>
			</v-col>
		</v-row>
	</div>
</template>

<script>

  export default{
		name: 'PaymentSmsCard'
		,props: ['config', 'callBack']
		,components: {}
		,data: function(){
			return {
				program: {
					name: 'PaymentSmsCard'
					,not_title: true
					,not_navigation: true
				}
				,show1: false
				,info: {
				}
				,item: {
					buyerTel: ''
					,cardNo: ''
					,cardPassword: ''
					,certification: ''
					,expireMm: ''
					,expireYy: ''
					,goodsName: ''
					,installment: '00'
					,pgMerchNo: ''
					,pgTid: ''
					,requestId: ''
					,requestKey: ''
					,totAmt: ''
                    ,agreementFinance: true
                    ,agreementPrivacy: true
					,memo: this.config.memo
				}
				,cardNumber: ['', '', '', '']
				,disabled: {
					save: true
					,installment: true
				}
				,rules: {
					expireMm: value => {
						let max = 2
						if(value.length > max){
							this.item.expireMm = this.item.expireMm.substring(0, max)
							return false
						}else{
							return true
						}
					}
					,expireYy: value => {
						let max = 2
						if(value.length > max){
							this.item.expireYy = this.item.expireYy.substring(0, max)
							return false
						}else{
							return true
						}
					}
					,card1: value => {
						let max = 4
						if(value.length > max){
							this.$set(this['cardNumber'], 0, this.cardNumber[0].substring(0, max))
							return false
						}else{
							return true
						}
					}
					,card2: value => {
						let max = 4
						if(value.length > max){
							this.$set(this['cardNumber'], 1, this.cardNumber[1].substring(0, max))
							return false
						}else{
							return true
						}
					}
					,card3: value => {
						let max = 4
						if(value.length > max){
							this.$set(this['cardNumber'], 2, this.cardNumber[2].substring(0, max))
							return false
						}else{
							return true
						}
					}
					,card4: value => {
						let max = 4
						if(value.length > max){
							this.$set(this['cardNumber'], 3, this.cardNumber[3].substring(0, max))
							return false
						}else{
							return true
						}
					}
					,cardPassword: value => {
						let max = 2
						if(value.length > max){
							this.item.cardPassword = this.item.cardPassword.substring(0, max)
							return false
						}else{
							return true
						}
					}
					,certification: value => {
						let max = 10
						if(value.length > max){
							this.item.certification = this.item.certification.substring(0, max)
							return false
						}else{

							return true
						}
					}
				}
				,codes: {
					installment: [
						{ code: '00', codeName: '일시불'}
						,{ code: '02', codeName: '2개월'}
						,{ code: '03', codeName: '3개월'}
						,{ code: '04', codeName: '4개월'}
						,{ code: '05', codeName: '5개월'}
						,{ code: '06', codeName: '6개월'}
					]
				}
			}
		}
		,computed: {
		}
		,methods: {
			next: function(){
				this.$emit('click')
			}
			,prev: function(){
				this.$emit('back')
			}
			,save: function(){
				this.$emit('axios', {
					request_type: 'post'
					,request_url: '/pay/sms'
					,request_params: this.item
					,callBack: 'result'
				})
			}
		}
		,created: function(){
		}
		,watch: {
			config: {
				immediate: true
				,handler: function(call){
					this.$set(this.item, 'goodsName', call.goodsName)
					this.$set(this.item, 'buyerTel', call.buyerContact)
					this.$set(this.item, 'pgMerchNo', call.pgMerchNo)
					this.$set(this.item, 'pgTid', call.pgTid)
					this.$set(this.item, 'requestId', this.$route.params.authorize)
					this.$set(this.item, 'requestKey', this.$route.params.index)
					this.$set(this.item, 'totAmt', call.paymentAmount)
					this.$set(this.item, 'installment', '00')
				}
			}
			,callBack: {
				deep: true
				,handler: function(call){
					if(call.name == 'result'){
						this.result(call)
					}
				}
			}
			,cardNumber: {
				deep: true
				,handler: function(call){
					this.item.cardNo = call[0].substring(0, 4) + '' + call[1].substring(0, 4) + '' + call[2].substring(0, 4) + '' + call[3].substring(0, 4)
				}
			}
			,item: {
				deep: true
				,handler: function(call){
					this.disabled.save = false
					for(const [key] of Object.entries(call)){
						if(key != 'memo' && !call[key]){
							this.disabled.save = true
							return
						}
					}
				}
			}
			,'item.totAmt': {
				immediate: true
				,handler: function(call){
					if(call > 50000){
						this.disabled.installment = false
					}else{
						this.disabled.installment = true
					}
				}
			}
		}
		
	}
</script>