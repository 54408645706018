<template>
	
	<div>
		
		<div
			class="sms-content"
		>
			<div class="mb-5 mt-1 font-weight-bold">
				결제정보
			</div>
			
			<v-text-field
				v-model="item.buyerName"
				label="고객명"
				outlined
				required
				hide-details
				color="success2"
				readonly
				class="pa-0"
				style="background: #eee;"
				
			></v-text-field>
			
			<v-text-field
				v-model="item.pgMerchNm"
				label="판매자"
				outlined
				required
				hide-details
				color="success2"
				readonly
				class="mt-5"
				style="background: #eee;"
			></v-text-field>
			
			<v-text-field
				v-model="item.buyerContact"
				label="연락처"
				outlined
				required
				hide-details
				color="success2"
				readonly
				class="mt-5"
				style="background: #eee;"
			></v-text-field>
			
			<v-text-field
				v-model="item.paymentAmount"
				label="결제금액"
				outlined
				required
				hide-details
				color="success2"
				readonly
				class="mt-5"
				style="background: #eee;"
			></v-text-field>
			
			<v-text-field
				v-model="item.goodsName"
				label="상품명"
				outlined
				required
				hide-details
				color="success2"
				readonly
				class="mt-5"
				style="background: #eee;"
			></v-text-field>

			<v-text-field
				v-model="item.memo"
				label="메모"
				outlined
				required
				hide-details
				color="success2"
				class="mt-5"
			></v-text-field>
			
			<div class="sms-agree mt-5">
				<v-checkbox
					v-model="agree.all"
					label="전체 동의"
					color="success2"
					hide-details
					class="d-inline-block pa-0 ma-0 font-weight-bold"
					@click="checkAgree"
				></v-checkbox>
				
				<div>
					<v-checkbox
						v-model="agree.trade"
						label="전자금융거래 이용약관"
						color="success2"
						hide-details
						class="d-inline-block pa-0 ma-0"
					></v-checkbox>
					<v-btn
						small
						text
						class="pa-0 float-right"
						@click="modal(0)"
					>보기<v-icon>mdi-chevron-right</v-icon></v-btn>
				</div>
				
				<div>
					<v-checkbox
						v-model="agree.personal"
						label="개인정보 처리방침"
						color="success2"
						hide-details
						class="d-inline-block pa-0 ma-0"
					></v-checkbox>
					<v-btn
						small
						text
						class="pa-0 float-right"
						@click="modal(1)"
					>보기<v-icon>mdi-chevron-right</v-icon></v-btn>
				</div>
			</div>
		</div>
		
		<div class="etc">
			<p class="title">법적고지</p>
			<p class="content">립페이는 전자지급결제대행업으로서 판매의 당사자가 아니며 상품의 주문, 배송 및 환불 등과 관련한 의무와 책임은 각 판매자에게 있습니다.</p>
		</div>
		
		<v-btn 
			class="mt-5 success2"
			block
			@click="next"
			:disabled="!agree.all"
		>
			다음
		</v-btn>
	</div>
</template>

<script>

	export default{
		name: 'PaymentSmsProduct'
		,props: ['config']
		,components: { }
		,data: function(){
			return {
				program: {
					name: 'PaymentSmsProduct'
					,not_title: true
					,not_navigation: true
				}
				,item: {
				}
				,agree: {
					all: false
					,trade: false
					,personal: false
				}
			}
		}
		,computed: {
		}
		,methods: {
			next: function(){
				console.log('product next')
				this.$emit('click', 'card')
			}
			,checkAgree: function(){
				if(this.agree.all){
					this.agree.trade = true
					this.agree.personal = true
				}else{
					this.agree.trade = false
					this.agree.personal = false
				}
			}
			,modal: function(index){
				this.$emit('modal', index)
			}
		}
		,created: function(){
		}
		,watch: {
			agree: {
				deep: true
				,handler: function(call){
					if(call.trade && call.personal){
						this.agree.all = true
					}else{
						this.agree.all = false
					}
				}
			}
			,config: {
				immediate: true
				,handler: function(call){
					this.item = call
				}
			}
		}
		
	}
</script>
<style>
	.sms-agree .v-label { font-size: 0.75em !important; color: black;}
	
	.etc { font-size: 0.8em; padding: 10px; border: 1px solid #ddd; border-top: none; background: #f8f8f8;}
	.etc .title { font-size: 1em !important; font-weight: bold; padding: 0; margin: 0;}
	.etc .content { font-size: 0.8em; color: #828282; line-height: 160%;}
</style>






















