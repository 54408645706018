<template>
	<div
		class="sms-wrap"
	>
		<div 
			class="sms-header"
		>
			<div class="title">
				ReapPay <span class="white1">Payments</span>
			</div>
			<div class="sub-title">
				SMS 결제 서비스
			</div>
		</div>
		
		<div
			class="sms-body"
		>
		<!-- 결제정보 Start -->
			<PaymentSmsProduct
				v-if="view.product"
				:config="item"

				@click="next('card')"
				@modal="modal"
				/>
			<!-- 결제정보 End -->

			<!-- 신용카드 정보 Start -->
			<PaymentSmsCard
				v-if="view.card"
				:config="item"

				@back="next('product')"
				@axios="axios"
				@save="checkPaymentStatus"
			/>
			<!-- 신용카드 정보 End -->

			<!-- 결제(내역)정보 Start -->
			<PaymentSmsResult
				v-if="view.result"
				:item="item"

				@click="receipt"
				@axios="axios"
				@modal="modal"
			/>
			<!-- 결제(내역)정보 End -->

			<!--	-->
			<PaymentSmsBill
				v-if="view.bill"
				:config="item"

				@click="next('result')"
			/>
			<!--	-->

			<!-- 결제 실패 Start -->
			<PaymentSmsFail
				v-if="view.fail"
				:statusCode="statusCode"
				:config="item"

				@rePayment="next('product')"
				@axios="axios"
			/>
			<!-- 결제 실패 End -->
		</div>

		<div
			class="sms-footer" 
		>
			Copyrights 2020 REAPPAY PAYMENTS. ALL Rights reserved.
		</div>
	</div>
</template>

<script>

	import PaymentSmsProduct from '@/views/Payment/sms/payment.sms.product'
	import PaymentSmsCard from '@/views/Payment/sms/payment.sms.card'
	import PaymentSmsResult from '@/views/Payment/sms/payment.sms.result'
	import PaymentSmsBill from '@/views/Payment/sms/payment.sms.bill'
	import PaymentSmsFail from '@/views/Payment/sms/payment.sms.fail'

	import { TERMS } from '@/values/ValueTerms.js'
	
	export default{
		name: 'PaymentSms'
		,props: ['callBack']
		,components: { PaymentSmsProduct, PaymentSmsCard, PaymentSmsResult, PaymentSmsBill, PaymentSmsFail }
		,data: function(){
			return {
				program: {
					name: 'PaymentSms'
					,not_title: true
					,not_navigation: true
				}
				,item: {
				}
				,view: {
					product: false
					,card: false
					,result: false
					,bill: false
					,fail: false
					,receipt: false
				}
				,agrees: [
					{
						name: '전자금융거래 이용약관'
						,contents: TERMS.TRANSACTION
					}
					,{
						name: '개인정보 수집/이용안내'
						,contents: TERMS.PERSONAL
					}
				]
				,statusCode: ''
				,cardInfo:{}
				,tranSeq: ''
				,resultInfo:{}
			}
		}
		,computed: {
		}
		,methods: {
			next: function(type){
				for(const[key] of Object.entries(this.view)){
					this.view[key] =false
				}
				this.view[type] = true
			}
			,axios: function(call){
				this.$emit('axios', call)
			}
			,alertMsg: function(type, msg){
				this.$emit('alertMsg', type, msg)
			}
			,modal: function(item){
				
				this.$emit('modal', item)
			}
			,getStatus: async  function(){
				this.$emit('axios', {
					request_type: 'get'
					,request_url: '/pay/sms/status'
					,request_params: {
						requestId: this.$route.params.authorize
						,requestKey: this.$route.params.index
					}
					,callBack: 'checkStatus'
				})
			}
			,checkStatus: async function(call){

				if(call.result && call.items.content){
					this.statusCode = call.items.content.statusCode

					if (this.statusCode == 'request') {
						await this.getData()
					}else if(this.statusCode == 'success'){
						this.tranSeq = call.items.content.tranSeq
						this.getResult(this.tranSeq)
					}else {
						this.next('fail')
					}
				}else{
					this.$emit('alertMsg', 'error', '존재하지 않는 결제 정보입니다')
					this.next('fail')
				}
			}
			,getData: async function(){
				this.$emit('axios', {
					request_type: 'get'
					,request_url: '/pay/sms'
					,request_params: {
						requestId: this.$route.params.authorize
						,requestKey: this.$route.params.index
					}
					,callBack: 'setItem'
				})
			}
			,setItem: function(call){

				if(call.result && call.items.content){
					this.item = call.items.content
					this.next('product')
				}else{
					this.$emit('alertMsg', 'error', '존재하지 않는 결제 정보입니다')
				}
			}
			,getResult: async function(tranSeq){
				this.$emit('axios', {
					request_type: 'get'
					,request_url: '/pay/sms/receipt/'
					,request_params: {
						requestId: this.$route.params.authorize
						,requestKey: this.$route.params.index
						,tranSeq: tranSeq
					}
					,callBack: 'setResult'
				})
			}
			,setResult: function(call){
				if(call.result && call.items.content){
					this.item = call.items.content
					this.next('result')
				}else{
					this.$emit('alertMsg', 'error', '존재하지 않는 결제 정보입니다')
				}
			}
			,result: function(call){
				if(call.result){
					if(call.items.content.payResponseCode == '0001' || call.items.content.payResponseCode == '0000' || call.items.content.payResponseCode == '00'){
						this.$emit('aletMsg', 'success', '결제가 완료되었습니다.')
						this.getResult(call.items.content.payResponseTranSeq)
					}else{
						this.$emit('alertMsg', 'error', call.items.content.payResponseMsg)
					}
				}else{
					this.$emit('alertMsg', 'error', call.items.message)
				}
			}
			,checkPaymentStatus: function(cardInfo){
				this.cardInfo = cardInfo
				this.$emit('axios', {
					request_type: 'get'
					,request_url: '/pay/sms/status'
					,request_params: {
						requestId: this.$route.params.authorize
						,requestKey: this.$route.params.index
					}
					,callBack: 'save'
				})
			}
			,save: function(call){
				if(call.result && call.items.content && call.items.content == 'request'){
					this.$emit('axios', {
						request_type: 'post'
						,request_url: '/pay/sms'
						,request_params: this.cardInfo
						,callBack: 'result'
					})
				}else {
					this.cardInfo = {}
					this.statusCode = call.items.content
					this.next('fail')
				}
			}
			,receipt: function() {
				this.$router.push('/Payment/Sms/Receipt/' + this.$route.params.authorize + '/' + this.$route.params.index + '/' + this.tranSeq)
			}
			,cancel: function(){
				this.$emit('axios', {
					request_type: 'post'
					,request_url: '/pay/cancel'
					,request_params: {
						tranSeq: this.tranSeq
					}
					,authorize: true
					,callBack: 'cancelResult'
				})
			}
			,cancelResult: function(call){
				if(call.success){
					this.$emit('alertMsg', 'success', '정상적으로 처리되었습니다')
				}else{
					this.$emit('alertMsg', 'error', call.items.message)
				}
			}
		}
		,created: function(){
			this.$emit('setProgram', this.program)
			this.getStatus()
		}
		,watch: {
			callBack: {
				deep: true
				,handler: function(call){
					if(call.name == 'setItem'){
						this.setItem(call)
					}else if(call.name == 'result'){
						this.result(call)
					}else if(call.name == 'checkStatus'){
						this.checkStatus(call)
					}else if(call.name == 'save'){
						this.save(call)
					}else if(call.name == 'receipt'){
						this.receipt(call)
					}else if(call.name == 'setResult'){
						this.setResult(call)
					}else if(call.name == 'cancel'){
						this.cancel(call)
					}else if(call.name == 'cancelResult'){
						this.cancelResult(call)
					}
				}
			}
		}
		
	}
</script>
<style>
	.sms-wrap { display: flex; flex-direction: column; height: 100%; background-color: #eee;}
	
	.sms-header { text-align: center; background: #153241;}
	.sms-header .title {padding: 20px 10px; color: #00BFA5 !important; font-size: 1.8em !important;}
	.sms-header .white1 {background-color: #15324e !important; color: white !important; font-size: 0.7em !important;}
	.sms-header .sub-title { background-color: #00BFA5; padding: 5px; color: white;}
	
	.sms-body { flex: 1; overflow: auto; padding: 10px;}
	.sms-body .sms-content { padding: 10px; background-color: white; border: 1px solid #ddd;}
	.sms-footer { maring-top: auto;	text-align: center; padding: 20px; font-size: 0.6em;}
	
</style>









